<template>
  <div class="page form" v-loading="loading">
    <h2>系统设置</h2>
 
    <section v-if="$dbus.account.role === 'admin'">
      <h3>全局消息</h3>
      <el-form class="form" :model="launch_msg" label-width="120px">
        <el-form-item label="标题">
          <el-input class="input-short" v-model="launch_msg.title"/>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" style="width: 300px" v-model="launch_msg.content"/>
        </el-form-item>
        <el-form-item label="跳转页面">
          <el-input style="width: 300px" v-model="launch_msg.url"/>
        </el-form-item>
        <el-form-item label="创建时间">
          {{ launch_msg.datetime_created }}
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmitLaunchMessage">发送</el-button>
          <el-button @click="onRemoveLaunchMessage">删除</el-button>
          <el-button type="success" @click="() => push_msg_show = true">推送消息</el-button>
        </el-form-item>
      </el-form>

      <el-dialog
          title="推送消息"
          :visible.sync="push_msg_show"
          width="500px">
        <el-form class="form" :model="push_msg" label-width="120px">
          <el-form-item label="活动名称">
            <el-input style="width: 300px" v-model="push_msg.title"/>
          </el-form-item>
          <el-form-item label="活动时间">
            <el-input style="width: 300px" v-model="push_msg.start_date"/>
          </el-form-item>
          <el-form-item label="活动地点">
            <el-input style="width: 300px" v-model="push_msg.location"/>
          </el-form-item>
          <el-form-item label="活动提示">
            <el-input type="textarea" style="width: 300px" v-model="push_msg.tip"/>
            <div class="tip">在内容主体上面展示</div>
          </el-form-item>
          <el-form-item label="活动说明">
            <el-input type="textarea" style="width: 300px" v-model="push_msg.remark"/>
            <div class="tip">在内容主体下面展示</div>
          </el-form-item>
          <el-form-item label="跳转页面">
            <el-input style="width: 300px" v-model="push_msg.url"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onPushGzhMessage">推送消息</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </section>
 

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import InputTags from "../core/components/form/input-tags"

export default {
  components: {
    InputTags,
    draggable
  },
  data() {
    return {
      loading: false,
      user: {},
      config: {},
      launch_msg: {},
      push_msg: {},
      push_msg_show: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.$api.request('/config_get').then(data => {
        this.config = data
        this.launch_msg = data.launch_msg || {}
      })
      this.user = await this.$api.request('/user/get', {id: this.$dbus.account.id})
      window.scrollTo(0, 0)
    },
    onAddCourseTapTag() {
      this.$prompt('请输入名称').then(({value}) => {
        this.config.course_classes = {...this.config.course_classes, [value]: []}
      })
    },
    onRemoveCourseTapTag(label) {
      this.$confirm('确认删除？').then(() => {
        delete this.config.course_classes[label]
        this.config.course_classes = {...this.config.course_classes}
      })
    },
    onSubmitUser() {
      this.$api.request('/user/update', this.user).then(data => {
        this.user = data
        this.$dbus.account = data
        this.$message.success('更新成功!')
      })
    },
    onSubmitConfig() {
      this.$api.request('/config_set', {value: this.config}).then(() => {
        this.load()
        this.$message.success('更新成功!')
      })
    },
    onChangePassword() {
      this.$prompt('请输入原密码').then(res1 => {
        this.$prompt('请输入新密码').then(res2 => {
          this.$api.request('/user/change_password', {
            id: this.$dbus.account.id,
            password_new: res2.value,
            password_old: res1.value
          }).then(() => {
            this.$message.success('密码修改成功！')
          })
        })
      })
    },

    onSubmitLaunchMessage() {
      this.$api.request('/create_launch_msg', {
        title: this.launch_msg.title,
        content: this.launch_msg.content,
        url: this.launch_msg.url,
      }).then(() => {
        this.$message.success('全局消息创建成功！')
      })
    },
    onRemoveLaunchMessage() {
      this.$api.request('/remove_launch_msg', {}).then(() => {
        this.$message.success('全局消息删除成功！')
        this.load()
      })
    },
    onPushGzhMessage() {
      this.$confirm('确认推送？').then(() => {
        this.$api.request('/send_active_msg', this.push_msg).then(() => {
          this.$message.success('推送成功！')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>


.vertical-tags {
  display: block !important;

  .el-tag {
    display: block !important;
  }
}

</style>

<style lang="scss">
.sbt-list {
  width: 500px;

  .el-upload--picture-card {
    display: block;
    background-color: white;
    border: none;
    width: 100px;
    height: 50px;
  }

  .el-upload-list__item {
    width: 180px;
    height: 90px;
  }
}
</style>

